uniform sampler2D uImg;
uniform sampler2D uImgMap;

uniform sampler2D uDisplacement;

uniform vec2 uMouse;
uniform vec2 uThreshold;

uniform vec4 uRes;
uniform float uRatio;

uniform float uFxAngle;

float PI = 3.14159265;

void main(){
    float effectRadius = 1.0;
    float effectAngle = uFxAngle * PI;

    //Responsive uv
    vec2 uv = uRatio * gl_FragCoord.xy / uRes.xy;

    //Fake3D uv 
    vec2 vUv = (uv - vec2(0.5)) * uRes.zw + vec2(0.5);

    //Center and Swirl
    vec2 center = vUv + vec2(0.5);
    vec2 uvSwirl = gl_FragCoord.xy / uRes.xy - center;

    //Fake3D
    vec4 depth = texture2D(uImgMap, vUv);
    vec4 displacement = texture2D(uDisplacement, vUv);

    //Hover Waves
    float theta = displacement.r * 2.0 * PI;
    vec2 dir = vec2(sin(theta), cos(theta));
    vec2 uvWave = vUv + dir * displacement.r * 0.1;

    //Swirl
    float len = length(uvSwirl * vec2(uRes.x / uRes.y, 1.0));
    float angle = atan(uvSwirl.y, uvSwirl.x) + effectAngle * smoothstep(effectRadius, 0.0, len);
    float radius = length(uvSwirl);
    vec2 swirl = vec2(radius * cos(angle), radius * sin(angle)) + center;

    vec2 fake3d = vec2( vUv.x + (depth.r - 0.5) * uMouse.x/uThreshold.x, 
                        vUv.y + (depth.r - 0.5) * uMouse.y/uThreshold.y);

    gl_FragColor = texture2D(uImg, (uvWave + fake3d + swirl)*0.25);
}