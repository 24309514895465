//Libs
global.THREE = require("three");
const THREE = global.THREE;
const {gsap} = require("gsap");

import FontFaceObserver from 'fontfaceobserver';
import imagesLoaded from 'imagesloaded';

//Doms
const loadingSVG = document.getElementById("svgCircle");
const gyroPermission = document.getElementById('gyroPermission'); 

//GyroBlock
if('ontouchstart' in window){
    gyroPermission.style.display = "block";
} else {
    gyroPermission.style.display = "none";
}

//Booleans
let flagLoading = false;
let flagMenuOpen = false;
let permissionGyro = false;

let countPress = 0;

const fragment = require("./shaders/fragment.glsl");
const vertex = require("./shaders/vertex.glsl");

const brush = require("../img/brush.png");

export default class IndexSketch{
    constructor(ops){
        //Main Images
        if('ontouchstart' in window){
            this.img = require("../img/mn-mob.jpg");
            this.imgMap = require("../img/mn-map-mob.jpg");
        } else {
            this.img = require("../img/mn-des.jpg");
            this.imgMap = require("../img/mn-map-des.jpg");
        }

        //Vars
        this.wrap = ops.dom;
        this.w = this.wrap.offsetWidth;
        this.h = this.wrap.offsetHeight;

        //Fake 3d
        this.ratio = (1/window.devicePixelRatio);
        this.imageAspect = 855/1280;
        this.a1 = 0;
        this.a2 = 0;
        this.mouseFake =  new THREE.Vector2(0,0);
        
        //Gyro
        this.beta = 0;
        this.gamma = 0;

        //Waves
        this.mouse = new THREE.Vector2(0,0);
        this.prevMouse = new THREE.Vector2(0,0);
        this.currentWave = 0;

        //Swirl
        this.isPress = false;
        this.fxAngle = new THREE.Vector2(0.0, 0.0);
        this.opacity = 1;


        //Renderer
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(this.w, this.h);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setClearColor(0xffffff, 1); //Omitir este paso?

        //Camera Orto
        let frustumSize = this.h;
        let aspect = this.w / this.h;
        this.camera = new THREE.OrthographicCamera(
            frustumSize * aspect / -2,
            frustumSize * aspect / 2,
            frustumSize / 2,
            frustumSize / -2,
            -1000,
            1000
            );
        this.camera.position.set(0,0,2);

        //Texture Scene
        this.baseTexture = new THREE.WebGLRenderTarget(
            this.w,
            this.h,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat
            }
        );

        //Scene
        this.sceneMain = new THREE.Scene();
        this.sceneBrush = new THREE.Scene();

        //Add domElement
        this.wrap.appendChild(this.renderer.domElement);
        
        const fontNeue = new Promise(resolve => {
            new FontFaceObserver("Neue Montreal").load().then(() => {
                resolve();
            });
        });

        const fontAvenir = new Promise(resolve => {
            new FontFaceObserver("Avenir Next").load().then(() => {
                resolve();
            });
        });

        let texturesLoaded = [this.img, this.imgMap];
        let resources = [fontNeue, fontAvenir];

        Promise.all(resources).then(() => {
            document.body.classList.add('fonts-added');
            loadingSVG.style.opacity = "1";
            this.animateCountSVG();
            imagesLoaded( texturesLoaded, () => {
                flagLoading = true;
            });
        });  
        this.onResize();    
        this.addEvents();  
        this.createObject();
        this.animation();
    }
    onResize(){
        this.w = this.wrap.offsetWidth;
        this.h = this.wrap.offsetHeight;

        //resize-shader
        if(this.h/this.w<this.imageAspect) {
            this.a1 = 1;
            this.a2 = (this.h/this.w) / this.imageAspect;
        } else{
            this.a1 = (this.w/this.h) * this.imageAspect ;
            this.a2 = 1;
        }

        this.renderer.setSize(this.w, this.h);

        this.camera.aspect = this.w / this.h;
        this.camera.updateProjectionMatrix();
    }
    mouseMove(e){
        let halfX =  this.wrap.offsetWidth/2;
        let halfY =  this.wrap.offsetHeight/2;
        
        //fake3D
        this.mouseFake.x = (e.touches) ? (halfX - e.touches[0].clientX)/halfX : (halfX - e.clientX)/halfX;
        this.mouseFake.y = (e.touches) ? (halfY - e.touches[0].clientY)/halfY : (halfY - e.clientY)/halfY;

        //Wavves
        this.mouse.x = (e.touches) ? e.touches[0].clientX - halfX : e.clientX - halfX;
        this.mouse.y = (e.touches) ? halfY - e.touches[0].clientY : halfY - e.clientY;
    }
    gyroscope(e){
        e.preventDefault();

        const maxTilt = 15;
        const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
        
        // iOS 13+
        if (DeviceMotionEvent && typeof DeviceMotionEvent.requestPermission === "function") {
            DeviceMotionEvent.requestPermission();
        }

        if (permissionGyro){
            window.removeEventListener("deviceorientation", (e) => {
                this.gamma = -clamp(e.gamma, -maxTilt, maxTilt)/maxTilt;
                this.beta = clamp(e.beta, -maxTilt, maxTilt)/maxTilt;
            });
            permissionGyro = false;
        } else {
            window.addEventListener("deviceorientation", (e) => {
                this.gamma = -clamp(e.gamma, -maxTilt, maxTilt)/maxTilt;
                this.beta = clamp(e.beta, -maxTilt, maxTilt)/maxTilt;
            });
            permissionGyro = true;
        }   
    }
    menu(e){
        if(e == "close"){
            flagMenuOpen = !flagMenuOpen;
            gsap.to("#menuInner", {duration:0.7, left:"100vw"});
            gsap.to("#menuBack", {delay:0.2, duration:0.7, left:"100vw"});
            gsap.to(".linkMenu", {'clip-path': 'polygon(0 0, 0 0, 0 100%, 0% 100%)', opacity: 0, stagger:0.3});
        } else {
            //Click Open Menu
            flagMenuOpen = !flagMenuOpen;
            gsap.to("#menuBack", {duration:0.7, left:"0"});
            gsap.to("#menuInner", {delay:0.2,duration:0.7, left:"0"});
            gsap.to(".linkMenu", {'clip-path': 'polygon(100% 0, 0 0, 0 100%, 100% 100%)', opacity: 1, delay:0.5, stagger:0.3});
        }
    }
    cursorInstruction(e){
        if(e == "up"){
            this.isPress = false;
        } else {
            this.isPress = true;
        }
    }
    addEvents(){
        this.menuBtns = document.querySelectorAll('.btnMenu');
        this.menuBtnsItems = [].slice.call(this.menuBtns);
        this.closeMenu = document.getElementById("closeMenu");

        if('ontouchstart' in window){
            //Mobile Events
            gyroPermission.addEventListener("click", this.gyroscope.bind(this));
            window.addEventListener("touchmove", this.mouseMove.bind(this));

            window.addEventListener("touchstart", () => {  this.cursorInstruction("down") });
            window.addEventListener("touchend", () => {  this.cursorInstruction("up") });
        } else {
            //Desktop Events
            window.addEventListener("mousemove", this.mouseMove.bind(this));

            window.addEventListener("mousedown", () => {  this.cursorInstruction("down") });
            window.addEventListener("mouseup", () => {  this.cursorInstruction("up") });
        }

        //Both Events
        this.menuBtnsItems.forEach(i => {
            i.addEventListener('click', () => {this.menu("click")});
        });
        this.closeMenu.addEventListener('click', () => { this.menu("close")});
        window.addEventListener("resize", this.onResize.bind(this));
    }
    animateCountSVG(){
        var id, count = 0;
        var percentages = document.querySelectorAll(".loadPerc");
        id = setInterval(countLoading, 80);
        function countLoading(){
            if(count > 96 && flagLoading){
                count = 100;
                clearInterval(id);
                gsap.to(".tspan", {opacity:0, stagger:0.3}); //2.40 total
                gsap.to("#loading", {delay:2.6, duration:1, opacity: 0, onComplete (){ 
                    gsap.to("#loading", {display:"none"});
                    gsap.to("#svgWr", {display:"none"});
                    
                    //Menu animation
                    gsap.to("#navbarDesktop", {duration:1, height:"100vh", opacity:"1"});
                    gsap.to("#navbarMobile", {duration:1, width:"100vw", opacity:"1"});
                    gsap.to(".navMain>h1", {'clip-path': 'polygon(100% 0, 0 0, 0 100%, 100% 100%)', opacity:1, delay:1, duration:1});
                    gsap.to(".navMain>button>div>span", {opacity: 1, stagger:0.5 })
                    gsap.to("#canvasWr", {delay:3, duration:1, opacity:"1"});
                }
            });
            } else {
                count+=6;
            }
            percentages.forEach(per => {
                per.innerHTML = "0"+count+"%";
            });
        }
        
    }
    createObject(){
        this.max = 50;

        this.mat = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            uniforms: {
                uImg: { value: new THREE.TextureLoader().load(this.img) },
                uImgMap: { value: new THREE.TextureLoader().load(this.imgMap) },
                uMouse: { value: {x:0.0, y:0.0} },
                uThreshold : { value: {x: 6.0, y: 6.0}},
                uRes: { value: {x: this.w, y: this.h, z: this.a1, w: this.a2}},
                uRatio: { value: this.ratio },
                uDisplacement: { value: null },
                uFxAngle: { value: 0.0 } 
            },
            fragmentShader: fragment,
            vertexShader: vertex

        });

        this.geoMain = new THREE.PlaneGeometry(this.wrap.offsetWidth,this.wrap.offsetHeight);
        this.geoBrush = new THREE.PlaneGeometry(30,70,1,1);

        this.meshes = [];

        //Brushes
        for(let i = 0; i < this.max; i++){
            let m = new THREE.MeshBasicMaterial({
                map: new THREE.TextureLoader().load(brush),
                transparent: true,
                blending: THREE.AdditiveBlending,
                depthTest: false,
                depthWrite: false
            });
            let mesh = new THREE.Mesh(this.geoBrush, m);
            mesh.visible = false;
            mesh.rotation.z = 2 * Math.PI * Math.random();
            this.sceneBrush.add(mesh);
            this.meshes.push(mesh);
        }

        this.mesh = new THREE.Mesh(this.geoMain, this.mat);

        this.sceneMain.add(this.mesh);
    }
    setNewWave(x,y,index){
        let mesh = this.meshes[index];
        mesh.visible = true;
        mesh.position.x = x;
        mesh.position.y = y;
        mesh.scale.x = mesh.scale.y = 1;
        mesh.material.opacity = 1;
    }
    trackMousePos(){
        if(Math.abs(this.mouse.x - this.prevMouse.x)<4 && Math.abs(this.mouse.y - this.prevMouse)<4){

        } else {
            this.setNewWave(this.mouse.x, this.mouse.y, this.currentWave);
            this.currentWave = (this.currentWave + 1)%this.max;
        }
        this.prevMouse.x = this.mouse.x;
        this.prevMouse.y = this.mouse.y;
    }
    trackMouseDown(){
        if(this.isPress){
            this.fxAngle.x += 0.05;
            //Falta la barra
        } else {
            gsap.to(this.fxAngle, {x:0, duration:0.5});
        }
    }
    animation(){
        if(this.isPress){
            countPress++;
            if((countPress)/60 > 2.5){
                console.log("Cambiar a about.html");
            } 
        } else {
            countPress = 0;
        }

        this.trackMousePos();
        this.trackMouseDown();

        //Gyro-or-mouse
        if('ontouchstart' in window){
            this.mat.uniforms.uMouse.value.x = this.gamma;
            this.mat.uniforms.uMouse.value.y = this.beta;
        } else {
            this.mat.uniforms.uMouse.value.x = this.mouseFake.x;
            this.mat.uniforms.uMouse.value.y = this.mouseFake.y;
        }

        this.mat.uniforms.uFxAngle.value = this.fxAngle.x;

        requestAnimationFrame(this.animation.bind(this));

        this.renderer.setRenderTarget(this.baseTexture);
        this.renderer.render(this.sceneBrush, this.camera);
        this.mat.uniforms.uDisplacement.value = this.baseTexture.texture;
        this.renderer.setRenderTarget(null);
        this.renderer.clear();

        this.renderer.render(this.sceneMain, this.camera);

        //Brush
        this.meshes.forEach(mesh => {
            if(mesh.visible){
                mesh.rotation.z += 0.02;
                mesh.material.opacity *= 0.96;
                mesh.scale.x = 0.98 * mesh.scale.x + 0.1;
                mesh.scale.y = 0.98 * mesh.scale.y + 0.1;
                if(mesh.material.opacity < 0.2){ mesh.visible = false }
            }
        });
    }
}

new IndexSketch({
    dom: document.getElementById('canvasWr')
});